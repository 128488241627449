import React from "react"
import { Link } from "gatsby"

const Header = () => (
  <header className="header">
    <div className="container">
      <div className="site">
        <Link to={`/jp/`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 783 328.19" width="112" height="46.59" xmlnsv="https://vecta.io/nano">
            <g fill="#333"><rect x=".4" y="74.88" width="65.8" height="187.12" rx={5} />
              <path d="M101.8 262a5 5 0 0 1-5-5V126.76a3 3 0 0 0-3-3H77.32a5 5 0 0 1-5-5V79.88a5 5 0 0 1 5-5h16.5a3 3 0 0 0 3-2.94c.45-23.08 7.1-40.1 20.92-53.6C129.72 6.37 146.6 0 166.6 0h.22c10.84 0 15.85 1.37 18.2 2.5a5 5 0 0 1 2.69 4.5v42a5 5 0 0 1-4.24 5l-4.43.66-.28.06-1 .22c-10.6 2.27-14.83 7-15.14 16.92a3 3 0 0 0 3 3.09h21.86a5 5 0 0 1 5 5v38.88a5 5 0 0 1-5 5H165.6a3 3 0 0 0-3 3V257a5 5 0 0 1-5 5zm187.55 2.16c-17.22 0-30.8-4.3-40.4-12.77-13-11.73-18.6-28.8-18.6-57v-67.6a3 3 0 0 0-3-3h-12.52a5 5 0 0 1-5-5V79.9a5 5 0 0 1 5-5h12.52a3 3 0 0 0 3-3V44.6a5 5 0 0 1 3.9-4.88l55.8-12.6a5.05 5.05 0 0 1 4.23 1 5 5 0 0 1 1.87 3.88v39.88a3 3 0 0 0 3 3h26.92a5 5 0 0 1 5 5v38.88a5 5 0 0 1-5 5h-26.92a3 3 0 0 0-3 3v62.56c0 15.82 5.82 18 13.4 19.18a63.18 63.18 0 0 0 12.18.71 5 5 0 0 1 5 5v41.7a5 5 0 0 1-3.78 4.85c-8.6 2.16-20.86 3.4-33.6 3.4z" />
                <rect x="338.95" y="74.88" width="65.8" height="187.12" rx={5} />
              <path d="M512.1 262a5 5 0 0 1-5-5V147.56c0-8.56-1.63-18.76-9.4-18.76-2.52 0-6.24.77-9.44 7.42l-.06.12a40.16 40.16 0 0 0-2.42 14.46V257a5 5 0 0 1-5 5H425a5 5 0 0 1-5-5V136.4q0-4.17 0-8.32c-.05-15.76-.1-32 1.6-48.7a5 5 0 0 1 5-4.49h44.88a5 5 0 0 1 4.95 4.29l.6 4.17a3 3 0 0 0 1.89 2.37 2.87 2.87 0 0 0 1.08.2 2.93 2.93 0 0 0 1.91-.69A57.41 57.41 0 0 1 519 72c19 0 33.85 7.77 42.8 22.47 7.42 12.18 11.17 29.43 11.17 51.3V257a5 5 0 0 1-5 5zm82.2 66.2h-1.85a5 5 0 0 1-4.65-3.49l-12-37.7a5 5 0 0 1 2.79-6.11c6.15-2.63 9.86-5 16.54-10.44a26.14 26.14 0 0 0 9.53-14.31 2 2 0 0 0 .07-.26 28.78 28.78 0 0 0 .83-5.68v-.1a27.57 27.57 0 0 0-.85-5.69L568.5 80.26a5 5 0 0 1 4.88-6.07h51.12a5 5 0 0 1 5 4.43l8.83 77.38a3 3 0 0 0 3 2.65h0a3 3 0 0 0 3-2.63l9.44-77.44a5 5 0 0 1 5-4.39h50.36a5 5 0 0 1 4.9 6L683.7 226.7c-5.24 25.24-10.27 47.54-18.66 64.67-6.94 14.17-20.14 22.76-30 27.47a97.89 97.89 0 0 1-40.76 9.35z" />
            </g>
            <circle cx="33.5" cy="33.55" r="33.5" fill="#962c2a" />
            <circle cx="371.5" cy="33.55" r="33.5" fill="#00527e" />
            <circle cx="749.5" cy="113.55" r="33.5" fill="#e1ba54" />
            <circle cx="749.5" cy="224.55" r="33.5" fill="#578256" />
          </svg>
        </Link>
      </div>
      <nav className="nav">
        <ul>
          <li>
            <Link to={`/jp/education/`}>教育</Link>
          </li>
          <li>
            <Link to={`/jp/iot/`}>IoT</Link>
          </li>
          <li>
            <Link to={`/jp/info/`}>お知らせ</Link>
          </li>
          <li>
            <Link to={`https://store.iftiny.com`}>ストア</Link>
          </li>
          <li>
            <Link to={`https://docs.iftiny.com/jp/`}>ドキュメント</Link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
)

export default Header