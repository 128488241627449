import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      pattern: file(relativePath: { eq: "pattern.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <footer className="footer">
      <div className="container">
        <div className="site">
          <Link to={`/jp/`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 782 328.22" width="162">
              <defs>
                <style dangerouslySetInnerHTML={{__html: ".cls-1{fill:#fff;}.cls-2{fill:#902c29;}.cls-3{fill:#04507a;}.cls-4{fill:#d9b555;}.cls-5{fill:#557f55;}" }} />
              </defs>
              <desc>ESSENTIALS</desc>
              <g id="レイヤー_2" data-name="レイヤー 2">
                <g id="レイヤー_1-2" data-name="レイヤー 1">
                  <rect className="cls-1" x="0.4" y="74.88" width="65.8" height="187.12" rx={5} />
                  <path className="cls-1" d="M101.8,262a5,5,0,0,1-5-5V126.73a3,3,0,0,0-3-3H77.32a5,5,0,0,1-5-5V79.88a5,5,0,0,1,5-5h16.5a3,3,0,0,0,3-2.94c.45-23.08,7.1-40.11,20.92-53.6C129.74,6.34,146.6,0,166.59,0h.22c10.83,0,15.84,1.37,18.13,2.51A5,5,0,0,1,187.71,7V49a5,5,0,0,1-4.24,5l-4.44.66-.27.06-1,.22c-10.58,2.27-14.82,7-15.13,16.91a3,3,0,0,0,3,3.1h21.87a5,5,0,0,1,5,5v38.8a5,5,0,0,1-5,5H165.62a3,3,0,0,0-3,3V257a5,5,0,0,1-5,5Z" />
                  <path className="cls-1" d="M289.35,264.16c-17.22,0-30.81-4.3-40.4-12.77-13-11.74-18.6-28.8-18.6-57V126.73a3,3,0,0,0-3-3H214.83a5,5,0,0,1-5-5V79.88a5,5,0,0,1,5-5h12.52a3,3,0,0,0,3-3V44.6a5,5,0,0,1,3.9-4.88l55.8-12.6a5.08,5.08,0,0,1,1.1-.12,5,5,0,0,1,3.12,1.1,4.94,4.94,0,0,1,1.88,3.9V71.88a3,3,0,0,0,3,3h26.92a5,5,0,0,1,5,5v38.85a5,5,0,0,1-5,5H299.15a3,3,0,0,0-3,3v62.56c0,15.82,5.81,18,13.41,19.18a62.88,62.88,0,0,0,12.18.71,5,5,0,0,1,5,5v41.71a5,5,0,0,1-3.79,4.85C314.35,262.92,302.09,264.16,289.35,264.16Z" />
                  <rect className="cls-1" x="338.95" y="74.88" width="65.8" height="187.12" rx={5} />
                  <path className="cls-1" d="M512.11,262a5,5,0,0,1-5-5V147.56c0-8.56-1.63-18.76-9.4-18.76-2.52,0-6.25.77-9.45,7.42l-.05.12a40,40,0,0,0-2.42,14.46V257a5,5,0,0,1-5,5H425a5,5,0,0,1-5-5V128c0-15.75-.11-32,1.6-48.67a5,5,0,0,1,5-4.49h44.88a5,5,0,0,1,5,4.29l.59,4.17A3,3,0,0,0,479,85.71a3,3,0,0,0,1.08.2,3,3,0,0,0,1.92-.69A57.38,57.38,0,0,1,519,72c19.32,0,33.71,7.56,42.79,22.47C569.24,106.65,573,123.9,573,145.76V257a5,5,0,0,1-5,5Z" />
                  <path className="cls-1" d="M594.3,328.19h-1.85a5,5,0,0,1-4.65-3.48L575.8,287a5,5,0,0,1,2.8-6.11c6.14-2.64,9.85-5,16.54-10.44a26.24,26.24,0,0,0,9.53-14.3l.06-.27a29.39,29.39,0,0,0,.84-5.68.24.24,0,0,0,0-.12,28.66,28.66,0,0,0-.85-5.67c-.13-.6-35.85-162.5-36.21-164.14a5,5,0,0,1,3.81-6,4.81,4.81,0,0,1,1.07-.12h51.12a5,5,0,0,1,5,4.43L638.37,156a3,3,0,0,0,3,2.66h0a3,3,0,0,0,3-2.63l9.45-77.44a5,5,0,0,1,5-4.39h50.36a5,5,0,0,1,5,5,4.78,4.78,0,0,1-.1,1L683.72,226.73c-5.25,25.24-10.27,47.54-18.66,64.67-7,14.17-20.15,22.76-30,27.47a97.76,97.76,0,0,1-40.76,9.35Z" />
                  <circle className="cls-2" cx="33.5" cy="33.55" r="33.5" />
                  <circle className="cls-3" cx="371.5" cy="33.55" r="33.5" />
                  <circle className="cls-4" cx="749.5" cy="113.55" r="33.5" />
                  <circle className="cls-5" cx="749.5" cy="224.55" r="33.5" />
                </g>
              </g>
            </svg>
          </Link>
            <br></br>
            <br></br>
            <ul>
              <Link to={`/jp/about/`}>会社情報</Link>&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to={`/jp/contact/`}>お問合せ</Link>
            </ul>
            <ul>
              <Link to={`/jp/faq/`}>FAQ</Link>&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to={`/jp/privacy-policy/`}>プライバシーポリシー</Link>
            </ul>
            <br></br>
        </div>
        
        <ul className="sns">
          <li>
            <a href="https://twitter.com/iftiny_inc">
              <FontAwesomeIcon icon={faTwitter} />
              <span className="sr-only">Twitter</span>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/iftiny">
              <FontAwesomeIcon icon={faFacebookSquare} />
              <span className="sr-only">Facebook</span>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/iftiny_inc/">
              <FontAwesomeIcon icon={faInstagram} />
              <span className="sr-only">Instagram</span>
            </a>
          </li>
        </ul>
      </div>
      
      <br></br>
      <div className="container">
        <ul className="copyright">
          Iftiny Inc. &copy; 2024
        </ul>
      </div>

      <div className="back">
        <Img
          fluid={data.pattern.childImageSharp.fluid}
          alt=""
          style={{ height: "100%" }}
        />
      </div>
    </footer>
  )
}

export default Footer
